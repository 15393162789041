<template>
  <div class="fill-height">
    <v-img
      :src="
        $vuetify.theme.dark ? require('@assets/dallara-gp208_white.png') : require('@assets/dallara-gp208_black.png')
      "
      height="250"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
    >
    </v-img>
    <v-container v-if="run" class="mt-2">
      <v-row>
        <v-col cols="12" md="6" lg="8">
          <testing-run-info-card :run="run">
            <v-spacer />
            <v-btn v-if="isMod" @click="showDeleteDialog = true" text>Delete</v-btn>
            <v-btn color="primary" @click="showEditDialog = true">Edit</v-btn>
          </testing-run-info-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-row>
            <v-col cols="12">
              <testing-run-soc-card :run="run" />
            </v-col>
            <v-col cols="12">
              <testing-run-driverless-card :run="run" />
            </v-col>
            <v-col cols="12">
              <!-- centered button in a card to download setup -->
              <v-card class="mx-auto">
                <v-card-title>Download Setup</v-card-title>
                <v-card-actions>
                  <v-btn color="primary" @click="downloadSetup">Download</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <!-- <v-col cols="12" md="6" lg="4">
          <testing-run-files-card
            ref="filesCard"
            :run="run"
            :measurementDataFileUploadProgress="measurementDataFileUploadProgress"
            :driverlessDataFileUploadProgress="driverlessDataFileUploadProgress"
            @upload="uploadFiles"
          /> -->
        <!-- </v-col> -->
        <v-col cols="12">
          <testing-run-analysis-card :run="run">
            <v-spacer />
            <v-btn color="primary" @click="showAfterRunDialog = true">Edit Driver Comms</v-btn>
          </testing-run-analysis-card>
        </v-col>
      </v-row>

      <v-divider class="my-3" />

      <v-row>
        <v-col cols="12">
          <v-card height="100%">
            <v-card-title>Laps</v-card-title>
            <testing-run-laps-info-table :laps="run.laps" />
          </v-card>
        </v-col>
      </v-row>

      <v-divider class="my-3" />

      <v-row>
        <v-col cols="12" md="6" lg="4">
          <v-card height="100%">
            <v-card-title class="blue--text text--lighten-3">Cold Tyre-Pressure</v-card-title>
            <setup-wheel-info v-if="run.coldTyrePressure" class="px-6" :config="run.coldTyrePressure" unit="bar" />
            <v-card-text v-else>
              <p>No Info provided!</p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <v-card height="100%">
            <v-card-title class="amber--text text--lighten-3">Warmup Tyre-Pressure</v-card-title>
            <setup-wheel-info v-if="run.warmupTyrePressure" class="px-6" :config="run.warmupTyrePressure" unit="bar" />
            <v-card-text v-else>
              <p>No Info provided!</p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <v-card height="100%">
            <v-card-title class="deep-orange--text text--lighten-3">Warm Tyre-Pressure</v-card-title>
            <setup-wheel-info v-if="run.warmTyrePressure" class="px-6" :config="run.warmTyrePressure" unit="bar" />
            <v-card-text v-else>
              <p>No Info provided!</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-divider class="my-3" />

      <v-row>
        <v-col cols="12" md="6" lg="4">
          <setup-ride-height-card :conf="run.setup.rideHeightConfig" />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <setup-weight-card :conf="run.setup.weightConfig" />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <setup-toe-card :conf="run.setup.toeConfig" />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <setup-camber-card :conf="run.setup.camberConfig" />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <setup-spring-card :conf="run.setup.springConfig" />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <setup-damper-card :conf="run.setup.damperConfig" />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <setup-tyres-card :setup="run.setup" />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <setup-driverless-card :setup="run.setup" />
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else style="height: calc(100% - 250px)">
      <v-row align="center" class="fill-height">
        <v-col align="center">
          <div class="text-h3 mb-6">Run does not exist in database yet! Please provide one!</div>
          <v-btn :to="{ name: 'Testing' }" color="primary">To Calendar</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-if="run" v-model="showEditDialog" fullscreen>
      <v-sheet min-height="100%" :color="this.$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-5'">
        <v-toolbar color="primary" style="position: fixed" absolute collapse>
          <v-btn icon @click="showEditDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Update Run</v-toolbar-title>
        </v-toolbar>
        <testing-run-data-form stage="update" :testingRun="run" :testingDay="run.day" @submit="updateRun" />
      </v-sheet>
    </v-dialog>

    <v-dialog v-if="run" v-model="showAfterRunDialog" max-width="500">
      <testing-run-after-run-form :testingRun="run" @submit="updateAfterRun" @close="showAfterRunDialog = false" />
    </v-dialog>

    <app-delete-dialog v-if="run" :show="showDeleteDialog" @delete="deleteRun" @close="showDeleteDialog = false">
      Delete a run will also delete all dependent laps and setup (ride-height, weight, etc.)! Are you sure your want
      delete this run?
    </app-delete-dialog>

    <v-snackbar v-model="savedRunSnackbar" timeout="2000" color="success" bottom>Saved run!</v-snackbar>
    <v-snackbar v-model="uploadedFileSnackbar" timeout="2000" color="success" bottom>Uploaded data!</v-snackbar>
  </div>
</template>

<script>
//import axios from 'axios'
//import { AuthType, createClient, WebDAVClient } from 'webdav/web'

import SetupWheelInfo from '@components/setup/WheelInfo.vue'
import SetupRideHeightCard from '@components/setup/ConfigRideHeightCard.vue'
import SetupWeightCard from '@components/setup/ConfigWeightCard.vue'
import SetupToeCard from '@components/setup/ConfigToeCard.vue'
import SetupCamberCard from '@components/setup/ConfigCamberCard.vue'
import SetupSpringCard from '@components/setup/ConfigSpringCard.vue'
import SetupDamperCard from '@components/setup/ConfigDamperCard.vue'
import SetupTyresCard from '@components/setup/ConfigTyresCard.vue'
import SetupDriverlessCard from '@components/setup/ConfigDriverlessCard.vue'
import AppDeleteDialog from '@components/app/DeleteDialog'
import TestingRunInfoCard from '@components/testingRuns/InfoCard.vue'
import TestingRunSocCard from '@components/testingRuns/SocCard.vue'
import TestingRunDriverlessCard from '@components/testingRuns/DriverlessCard.vue'
import TestingRunAnalysisCard from '@components/testingRuns/AnalysisCard.vue'
// import TestingRunFilesCard from '@components/testingRuns/FilesCard.vue'
import TestingRunLapsInfoTable from '@components/testingRuns/LapsInfoTable.vue'
import TestingRunDataForm from '@components/testingRuns/DataForm.vue'
import TestingRunAfterRunForm from '@components/testingRuns/AfterRunForm.vue'

import { TestingRun, DeleteTestingRun, UpdateTestingRun } from '@graphql/TestingRuns.gql'
import { UserInfo } from '@graphql/Users.gql'

function getUpsert(o) {
  return {
    update: Object.fromEntries(
      Object.entries(o)
        .filter(([k]) => k !== 'id')
        .filter(([, v]) => !!v)
        .map(([k, v]) => [k, { set: v }])
    ),
    create: o
  }
}

/* const nextcloudURI =
  process.env.NODE_ENV === 'production' ? 'https://cloud.dhbw-engineering.de' : 'http://localhost:8081'
const nextcloudConfig = {
  username: process.env.VUE_APP_NEXTCLOUD_USERNAME,
  password: process.env.VUE_APP_NEXTCLOUD_PASSWORD,
  fileRoot: '/00_Shared/Messdaten'
}

const userClient = createClient(`${nextcloudURI}/remote.php/dav/files/${nextcloudConfig.username}`, {
  authType: AuthType.None
})

const zeroPad = (num, places) => String(num).padStart(places, '0')
 */
export default {
  name: 'TestingRun',
  components: {
    AppDeleteDialog,
    TestingRunInfoCard,
    TestingRunSocCard,
    TestingRunDriverlessCard,
    TestingRunAnalysisCard,
    // TestingRunFilesCard,
    TestingRunLapsInfoTable,
    TestingRunDataForm,
    TestingRunAfterRunForm,
    SetupWheelInfo,
    SetupRideHeightCard,
    SetupWeightCard,
    SetupToeCard,
    SetupCamberCard,
    SetupSpringCard,
    SetupDamperCard,
    SetupTyresCard,
    SetupDriverlessCard
  },
  apollo: {
    run: {
      query: TestingRun,
      variables() {
        return { where: { id: this.$route.params.id } }
      },
      update: (data) => data.testingRun
    },
    loggedUser: { query: UserInfo }
  },
  data: () => ({
    showEditDialog: false,
    showDeleteDialog: false,
    showAfterRunDialog: false,
    run: undefined,
    savedRunSnackbar: false,
    uploadedFileSnackbar: false,
    measurementDataFileUploadProgress: 0,
    driverlessDataFileUploadProgress: 0
  }),
  computed: {
    isMod() {
      if (!this.loggedUser) return false
      if (this.loggedUser.role !== 'MODERATOR' && this.loggedUser.role !== 'ADMIN') return false
      return true
    }
  },
  methods: {
    goBack() {
      // Implement your logic to go back here
      // For example, you can use Vue Router's $router to navigate back
      this.$router.back()
    },

    downloadSetup() {
      const data = this.run
      const filename = `testing-run-${data.id}-setup.json`
      const json = JSON.stringify(data.setup)
      const blob = new Blob([json], { type: 'application/json' })
      const href = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = href
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    /* uploadFiles(data) {
      const uploadPromises = []

      if (data.measurementDataFile) {
        const prom = new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            console.log('done')
            const root = nextcloudConfig.fileRoot
            const carName = `${this.run.day.car.name} (${this.run.day.car.season})`
            const dayName = `${this.run.day.date.split('T')[0]} - ${this.run.day.ground} - ${this.run.day.title}`
            // root/Car (Season)/Date - Ground - Title
            const dirname = `${root}/${carName}/${dayName}`
            // RUN XXX - Title
            const filename = `RUN ${zeroPad(this.run.number, 3)} - ${this.run.title || 'Unnamed'}.mat`
            const destination = `${dirname}/${filename}`

            userClient
              .putFileContents(destination, reader.result, {
                onUploadProgress: function (p) {
                  this.measurementDataFileUploadProgress = (100 * p.loaded) / p.total
                }.bind(this)
              })
              .then((uploaded) => {
                console.log(uploaded)
                if (!uploaded) {
                  this.measurementDataFileUploadProgress = 0
                  return reject(true)
                }

                const formData = new FormData()
                formData.append('carName', this.run.day.car.name)
                formData.append('carSeason', this.run.day.car.season)
                formData.append('dayGround', this.run.day.ground)
                formData.append('dayDate', this.run.day.date)
                formData.append('dayTitle', this.run.day.title)
                formData.append('runNumber', this.run.number)
                formData.append('runTitle', this.run.title)
                formData.append('runId', this.run.id)

                formData.append('file', data.measurementDataFile)
                axios
                  .post('/api/upload/measurementdata', formData, {
                    onUploadProgress: function (p) {
                      this.measurementDataFileUploadProgress = (100 * p.loaded) / p.total
                    }.bind(this)
                  })
                  .then((response) => {
                    this.run.measurementData = response.data
                    this.measurementDataFileUploadProgress = 0
                    resolve(true)
                  })
                  .catch((err) => {
                    this.measurementDataFileUploadProgress = 0
                    this.$store.commit('openErrorDialog', err)
                    reject(true)
                  })
              })
              .catch((err) => {
                this.measurementDataFileUploadProgress = 0
                this.$store.commit('openErrorDialog', err)
                reject(true)
              })
          }
          console.log('Start reading file')
          reader.readAsBinaryString(data.measurementDataFile)
        })
        uploadPromises.push(prom)
      }

      if (data.driverlessDataFile) {
        const prom = new Promise((resolve, reject) => {
          const formData = new FormData()
          formData.append('carName', this.run.day.car.name)
          formData.append('carSeason', this.run.day.car.season)
          formData.append('dayGround', this.run.day.ground)
          formData.append('dayDate', this.run.day.date)
          formData.append('dayTitle', this.run.day.title)
          formData.append('runNumber', this.run.number)
          formData.append('runTitle', this.run.title)
          formData.append('runId', this.run.id)

          formData.append('file', data.driverlessDataFile)
          axios
            .post('/api/upload/driverlessdata', formData, {
              onUploadProgress: function (p) {
                this.driverlessDataFileUploadProgress = (100 * p.loaded) / p.total
              }.bind(this)
            })
            .then((response) => {
              this.run.driverlessData = response.data
              this.driverlessDataFileUploadProgress = 0
              resolve(true)
            })
            .catch((res) => {
              this.driverlessDataFileUploadProgress = 0
              this.$store.commit('openErrorDialog', res)
              reject(true)
            })
        })
        uploadPromises.push(prom)
      }

      Promise.all(uploadPromises).then(() => {
        this.uploadedFileSnackbar = true
        this.$refs.filesCard.closeUpload()
      })
    }, */
    updateAfterRun(data) {
      const variables = {
        where: { id: this.run.id },
        data: {
          driverCommunication: { set: data.driverCommunication }, //gained knowledge is now driver communication
          driverFeedback: { set: data.driverFeedback }
        }
      }

      this.$apollo
        .mutate({ mutation: UpdateTestingRun, variables })
        .then(({ data }) => {
          this.run = data.updateTestingRun
          this.savedRunSnackbar = true
          this.showDriverCommunicationDialog = false
        })
        .catch((res) => {
          this.$store.commit('openErrorDialog', res)
        })
    },
    updateRun({ data, close }) {
      const variables = {
        where: { id: this.run.id },
        data: {
          title: { set: data.title },
          notes: { set: data.notes },
          success: { set: data.success },
          driverless: { set: data.driverless },
          start: { set: data.start },
          end: { set: data.end },
          airTemperature: { set: data.airTemperature },
          trackTemperature: { set: data.trackTemperature },
          weatherConditions: { set: data.weatherConditions },
          trackConditions: { set: data.trackConditions },
          windSpeed: { set: data.windSpeed },
          windDirection: { set: data.windDirection },
          camera: { set: data.camera },
          lidar: { set: data.lidar },
          motionController: { set: data.motionController },
          motionPlanning: { set: data.motionPlanning },
          slam: { set: data.slam },
          hvSocStart: { set: data.hvSocStart },
          hvSocEnd: { set: data.hvSocEnd },
          lvSocStart: { set: data.lvSocStart },
          lvSocEnd: { set: data.lvSocEnd },
          manoeuvreId: { set: data.manoeuvreId },
          driverId: { set: data.driverId },
          secondDriverId: { set: data.secondDriverId },

          laps: {
            deleteMany: this.run.laps.map((lap) => ({ id: { equals: lap.id } })),
            create: data.laps
          }
        }
      }

      if (data.coldTyrePressure) variables.data.coldTyrePressure = { upsert: getUpsert(data.coldTyrePressure) }
      if (data.warmupTyrePressure) variables.data.warmupTyrePressure = { upsert: getUpsert(data.warmupTyrePressure) }
      if (data.warmTyrePressure) variables.data.warmTyrePressure = { upsert: getUpsert(data.warmTyrePressure) }

      variables.data.setup = {
        update: {
          dvMounted: { set: data.setup.dvMounted },
          tyres: { set: data.setup.tyres },
          tyreAge: { set: data.setup.tyreAge },
          tyreSet: { set: data.setup.tyreSet }
        }
      }
      if (data.setup.springConfig)
        variables.data.setup.update.springConfig = { upsert: getUpsert(data.setup.springConfig) }

      if (data.setup.damperConfig)
        variables.data.setup.update.damperConfig = { upsert: getUpsert(data.setup.damperConfig) }

      if (data.setup.rideHeightConfig)
        variables.data.setup.update.rideHeightConfig = { upsert: getUpsert(data.setup.rideHeightConfig) }

      if (data.setup.weightConfig)
        variables.data.setup.update.weightConfig = { upsert: getUpsert(data.setup.weightConfig) }

      if (data.setup.toeConfig) variables.data.setup.update.toeConfig = { upsert: getUpsert(data.setup.toeConfig) }

      if (data.setup.camberConfig)
        variables.data.setup.update.camberConfig = { upsert: getUpsert(data.setup.camberConfig) }

      if (data.setup.targetTyrePressure)
        variables.data.setup.update.targetTyrePressure = { upsert: getUpsert(data.setup.targetTyrePressure) }

      this.$apollo
        .mutate({ mutation: UpdateTestingRun, variables })
        .then(({ data }) => {
          this.run = data.updateTestingRun
          if (close) this.showEditDialog = false
          this.savedRunSnackbar = true
        })
        .catch((res) => {
          this.$store.commit('openErrorDialog', res)
        })
    },
    deleteRun() {
      const variables = { where: { id: this.run.id } }
      this.$apollo
        .mutate({ mutation: DeleteTestingRun, variables })
        .then(() => {
          const date = this.run.day.date.substr(0, 10)
          this.$router.push({ name: 'TestingDay', params: { date } })
        })
        .catch((res) => {
          this.$store.commit('openErrorDialog', res)
        })
      this.showDeleteDialog = false
    }
  }
}
</script>
