<template>
  <v-data-table :headers="headers" :items="laps" sort-by="number" style="white-space: pre-wrap; word-wrap: break-word">
    <template v-slot:footer.prepend>
      <v-spacer />
      <v-switch v-model="showLimits" label="Limits" class="mr-5" />
    </template>
    <template v-slot:item.start="{ item }">
      {{ convertTime(item.start) }}
    </template>
    <template v-slot:item.end="{ item }">
      {{ convertTime(item.end) }}
    </template>
  </v-data-table>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'LapInfoTable',
  props: { laps: { type: Array, required: true } },
  data: () => ({
    showLimits: false
  }),
  methods: {
    convertTime(datetime) {
      return datetime ? DateTime.fromISO(datetime).toFormat('HH:mm') : undefined
    }
  },
  computed: {
    headers() {
      let headers = [
        { text: 'Number', align: 'start', value: 'number' },
        { text: 'Start Time', value: 'start' },
        { text: 'End Time', value: 'end' },
        { text: 'Lap-Time (s)', value: 'time' },
        { text: 'Cone count', value: 'coneCount' },
        { text: 'Offtrack', value: 'offTracks' },
        { text: 'Occurences / Notes', value: 'occurences' }
      ]

      if (this.showLimits) {
        headers.push(
          { text: 'Velocity Limit (m/s)', value: 'velocityLimit' },
          { text: 'Power Limit (kW)', value: 'powerLimit' },
          { text: 'Negative Power Limit (kW)', value: 'negativePowerLimit' },
          { text: 'Torque Limit (kW)', value: 'torqueLimit' },
          { text: 'Negative Torque Limit (kW)', value: 'negativeTorqueLimit' },
          { text: 'Torque Vectoring', value: 'torqueVectoring' },
          { text: 'Traction Control', value: 'tractionControl' }
        )
      }
      return headers
    }
  }
}
</script>
