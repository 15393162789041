<template>
  <v-card>
    <v-card-title> Driver </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-textarea v-model="local.driverCommunication" label="Driver Communication" auto-grow />
        <v-textarea v-model="local.driverFeedback" label="Driver Feedback" auto-grow />
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')"> Close </v-btn>
      <v-btn text color="primary" @click="submit"> Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { cloneDeep as _cloneDeep } from 'lodash/lang'

export default {
  name: 'TestingRunAfterRunForm',
  props: {
    testingRun: {
      type: Object,
      default() {
        return {
          driverCommunication: undefined,
          // gainedKnowledge: undefined,
          driverFeedback: undefined
        }
      }
    }
  },
  data: () => ({
    valid: false,
    local: {}
  }),
  created() {
    this.local = _cloneDeep(this.testingRun)
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) return

      const data = {
        driverCommunication: this.local.driverCommunication,
        // gainedKnowledge: this.local.gainedKnowledge,
        driverFeedback: this.local.driverFeedback
      }

      // Goes to 'views/TestingRun.vue -> methods: updateRun'
      this.$emit('submit', data)
      return data
    }
  }
}
</script>
