<template>
  <v-card height="100%">
    <v-card-title>Driverless</v-card-title>
    <v-card-text>
      <v-row v-if="run.driverless">
        <v-col cols="12">
          <div class="text-overline">Camera</div>
          <div class="text-body-01 text-multiline">{{ run.camera || '-' }}</div>
        </v-col>
        <v-col cols="12">
          <div class="text-overline">LiDAR</div>
          <div class="text-body-01 text-multiline">{{ run.lidar || '-' }}</div>
        </v-col>
        <v-col cols="12">
          <div class="text-overline">Motion Controller</div>
          <div class="text-body-01 text-multiline">{{ run.motionController || '-' }}</div>
        </v-col>
        <v-col cols="12">
          <div class="text-overline">Motion Planning</div>
          <div class="text-body-01 text-multiline">{{ run.motionPlanning || '-' }}</div>
        </v-col>
        <v-col cols="12">
          <div class="text-overline">SLAM</div>
          <div class="text-body-01 text-multiline">{{ run.slam || '-' }}</div>
        </v-col>
      </v-row>
      <v-alert v-else text dense type="info">Driverless components not mounted</v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TestingRunDriverlessCard',
  props: { run: { type: Object, required: true } }
}
</script>
