<template>
  <v-card height="100%">
    <v-card-title>{{ run.title || 'Unnamed' }}</v-card-title>
    <v-card-subtitle>
      <v-btn icon :to="dayTo"><v-icon>mdi-arrow-left</v-icon></v-btn>
      {{ run.day.title }} - Run {{ run.number }}
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <v-alert v-if="run.success" text dense type="success">Run was successfull</v-alert>
          <v-alert v-else text dense type="error">Run was not successfull</v-alert>
          <v-row>
            <v-col>
              <div class="text-overline">CEK Responsible</div>
              <v-chip>
                <v-avatar left> <v-img :src="run.responsible.googlePicture"></v-img> </v-avatar>
                {{ run.responsible.fullname }}
              </v-chip>
            </v-col>
            <v-col cols="12" sm="6" v-if="run.driverless">
              <div class="text-overline">Driven by</div>
              <v-alert text dense type="info">Driverless</v-alert>
            </v-col>
            <v-col cols="12" sm="6" v-else>
              <div class="text-overline">Driven by</div>
              <v-chip>
                <v-avatar left> <v-img :src="run.driver.googlePicture"></v-img> </v-avatar>
                {{ run.driver.fullname }}
              </v-chip>
              <div style="height: 6px" />
              <v-chip v-if="run.second_driver">
                <v-avatar left> <v-img :src="run.second_driver.googlePicture"></v-img> </v-avatar>
                {{ run.second_driver.fullname }}
              </v-chip>
            </v-col>
          </v-row>

          <v-divider class="mt-2" />

          <div class="text-overline">Time</div>
          <div class="text-body-01">
            <v-icon dense>mdi-clock</v-icon> {{ formattedStartTime }} - {{ formattedEndTime }}
          </div>

          <v-divider class="mt-2" />

          <v-row>
            <v-col cols="12" lg="6">
              <div class="text-overline">Weather Conditions</div>
              <div class="text-body-01" v-if="run.airTemperature || run.weatherConditions">
                {{ run.airTemperature }} °C - {{ run.weatherConditions }}
              </div>
              <div class="text-body-01" v-else>No Weather Conditions provided!</div>
            </v-col>

            <v-col cols="12" lg="6">
              <div class="text-overline">Track Conditions</div>
              <div class="text-body-01" v-if="run.trackTemperature || run.trackConditions">
                {{ run.trackTemperature }} °C - {{ run.trackConditions }}
              </div>
              <div class="text-body-01" v-else>No Track Conditions provided!</div>
            </v-col>

            <v-col cols="12" lg="6">
              <div class="text-overline">Wind Conditions</div>
              <div class="text-body-01" v-if="run.windSpeed || run.windDirection">
                {{ run.windSpeed || '?' }} m/s {{ run.windDirection }}
              </div>
              <div class="text-body-01" v-else>No Wind Conditions provided!</div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card color="info" outlined>
            <v-card-text>
              <p class="text-overline">{{ run.manoeuvre.title }}</p>
              <v-icon dense>mdi-test-tube</v-icon> {{ run.manoeuvre.description }}
            </v-card-text>
          </v-card>

          <v-divider />

          <div class="text-overline">Notes</div>
          <div class="text-body-01 text-multiline" v-if="run.notes">{{ run.notes }}</div>
          <div class="text-body-01" v-else>No Notes provided!</div>

          <!-- <div class="text-overline">Driver Feedback</div>
          <div class="text-body-01 text-multiline" v-if="run.driverFeedback">{{ run.driverFeedback }}</div>
          <div class="text-body-01" v-else>No Feedback provided!</div>

          <v-divider />

          <div class="text-overline">Gained Knowledge / Analysis Done</div>
          <div class="text-body-01 text-multiline" v-if="run.gainedKnowledge">{{ run.gainedKnowledge }}</div>
          <div class="text-body-01" v-else>No Knowledge provided!</div> -->
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <slot />
    </v-card-actions>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'TestingRunInfoCard',
  props: { run: { type: Object, required: true } },
  computed: {
    formattedDate() {
      return DateTime.fromISO(this.run.day.date).toFormat('dd LLL yyyy')
    },
    formattedStartTime() {
      return DateTime.fromISO(this.run.start).toFormat('HH:mm')
    },
    formattedEndTime() {
      return DateTime.fromISO(this.run.end).toFormat('HH:mm')
    },
    dayTo() {
      const date = this.run.day.date.substr(0, 10)
      return { name: 'TestingDay', params: { date } }
    }
  }
}
</script>
