<template>
  <v-card height="100%">
    <v-card-title>Driver</v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="6">
          <div class="text-overline">Driver Communication</div>
          <div class="text-body-01" v-if="run.driverCommunication">{{ run.driverCommunication }}</div>
          <div class="text-body-01" v-else>No Communication!</div>
        </v-col>
        <v-col cols="6">
          <div class="text-overline">Driver Feedback</div>
          <div class="text-body-01 text-multiline" v-if="run.driverFeedback">{{ run.driverFeedback }}</div>
          <div class="text-body-01" v-else>No Feedback provided!</div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <slot />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'TestingRunAnalysisCard',
  props: { run: { type: Object, required: true } }
}
</script>
