<template>
  <v-card height="100%">
    <v-card-title>Electrical Components</v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-if="run.hvSocStart || run.hvSocEnd">
          <div class="text-overline">HV SOC</div>
          <div class="text-body-01">Start: {{ run.hvSocStart || '-' }}%</div>
          <div class="text-body-01">End: {{ run.hvSocEnd || '-' }}%</div>
        </v-col>
        <v-col v-else>
          <div class="text-overline">HV SOC</div>
          <div class="text-body-01">No HV SOC provided</div>
        </v-col>
        <v-col v-if="run.lvSocStart || run.lvSocEnd">
          <div class="text-overline">LV SOC</div>
          <div class="text-body-01">Start: {{ run.lvSocStart || '-' }}%</div>
          <div class="text-body-01">End: {{ run.lvSocEnd || '-' }}%</div>
        </v-col>
        <v-col v-else>
          <div class="text-overline">LV SOC</div>
          <div class="text-body-01">No LV SOC provided</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TestingRunSocCard',
  props: { run: { type: Object, required: true } }
}
</script>
